import { useEffect } from "react";
import "./CompleteOrder.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../data/env";

const OrderComplete = () => {
    let { id } = useParams();
    useEffect(() => {
        apicall();
    }, [])

    useEffect(() => {
        localStorage.removeItem("cart");
    }, [])


    const apicall = () => {
        console.log("abc====", id)
        axios.patch(`${apiUrl}/api/v1/siteorder/paymentStatusUpdate/${id}`)
            .then((res) => {
                console.log("muzzamal", res.data);
            })
            .catch((err) => console.log(err));
    }
    return (
        <>
            <body className="body" style={{ marginBottom: "90vh" }}>
                <div class="contanier">
                    <div class="main" style={{ marginTop: "200px", marginLeft: "30%" }}>
                        <h1 style={{ color: "black", fontwe: "bolder", marginLeft: "50px" }}>Order Placed</h1>
                        <div class="road"  >
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            <div class="bus" >
                                <div class="back">
                                    <div class="back1door"></div>
                                    <div class="back2door"></div>
                                    <div class="join"></div>
                                </div>
                                <div class="front">
                                    <div class="black"></div>
                                    <div class="light1"></div>
                                    <div class="light2"></div>
                                </div>
                            </div>
                            <div class="gift"></div>
                        </div>
                    </div>
                </div>
            </body>


        </>
    )
}
export default OrderComplete;